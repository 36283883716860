<section class="welcome-screen-modal" *ngIf="welcomeScreenModel">
    <div class="locations-sections container">
        <div (click)="close()" class="close-action">
            <nb-icon pack="great-pack" icon="close-modal-icon"></nb-icon>
        </div>
        <div class="row w-100 m-0 justify-content-center">
                <div class="select-location-box" @slowAnimate  *ngIf="welcomeScreenModel?.locationOrders?.length > 0">
                    <div [class]="'config-locations-header w-100 position_align_' + welcomeScreenModel.textAlign" class=""  *ngIf="actionStatus !== 2">
                      {{welcomeScreenModel.text}}
                    </div>
                    <div *ngIf="welcomeScreenModel?.description?.length > 0 && welcomeScreenModel.orientationPosition == 'top'"
                    [innerHtml]="welcomeScreenModel.description">
                    </div>
                    <div class="locations-card-list">
                        <div class="select-location-item" (click)="selectLocation(location)" *ngFor="let location of welcomeScreenModel?.locationOrders, let i = index" [ngStyle]="{'width.%': 100 / welcomeScreenModel.itemsPerRow}">
                           <div   
                           [style.min-width]="welcomeScreenModel.width == 0 ? '150px' : 'unset'"
                           [style.min-height]="welcomeScreenModel.height == 0 ? '150px' : 'unset'"
                           [style.width]="welcomeScreenModel.width === 0 ? 'auto' : welcomeScreenModel.width + 'px'"
                           [style.height]="welcomeScreenModel.height === 0 ? '100%' : welcomeScreenModel.height + 'px'" class="location-card card-item-margin ">
                             <div class="logo-box">
                                <img *ngIf="welcomeScreenModel.type == 'logo' || welcomeScreenModel.type == 'both'"
                                class="welcome"  [style.max-width]=" welcomeScreenModel.logoWidth == 0 ? '150px' : 'unset'"
                                [style.width]=" welcomeScreenModel.logoWidth === 0 ? '100%' :  welcomeScreenModel.logoWidth + 'px'"
                             
                                [src]="location.locationBrandingLogo" alt="">
                                <div class="name-location" *ngIf="welcomeScreenModel.type == 'text' || welcomeScreenModel.type == 'both'">
                                    {{location.locationName}}
                                </div>
                                <div *ngIf="location.locationDescription.length > 0" class="" [innerHtml]="location.locationDescription"></div>
                             </div>
                           </div>
                       
                        </div>
                    </div>
                    <div class="mt-4" *ngIf="welcomeScreenModel?.description?.length > 0 && welcomeScreenModel?.orientationPosition == 'bottom'"
                        [innerHtml]="welcomeScreenModel.description">
                     </div>
                </div>
        </div>
    </div>

</section>