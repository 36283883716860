<div class="backdrop" (click)="closeComponent()" *ngIf="showMultiShadeSelect"></div>
<!--  -->
<div class="assign-section">
  <div class="management-assign-clients">
    <div class="clients-container">
      <div class="clients-actions-box" (mouseenter)="showMultiShadeSelect = true">
        {{ selectedShadesString ? selectedShadesString : "Select Shades" }}
        <img src="../../../../assets/images/icons/down-arrow-select.svg" alt="">
      </div>
      <div class="error-text" *ngIf="isNotEnoughData">
        Please select all shades
      </div>
      <div (mouseleave)="showMultiShadeSelect = false" class="selected-popover-container"
        [ngClass]="{'active' : showMultiShadeSelect}">
        <div class="client-list">
          <div class="shade-text" [ngClass]="{'selected': shadesCount === 1}" (click)="openDropdown(1)"
            (mouseenter)="openDropdownHover(1)">1 Shade</div>
          <div class="shade-text" [ngClass]="{'selected': shadesCount === 2}" (click)="openDropdown(2)"
            (mouseenter)="openDropdownHover(2)">2 Shades</div>
          <div class="shade-text" [ngClass]="{'selected': shadesCount === 3}" (click)="openDropdown(3)"
            (mouseenter)="openDropdownHover(3)">3 Shades</div>
          <div class="shade-text" (click)="selectTBD()" [ngClass]="{'selected': shadesCount === 0}">
            No Shade (TBD)
          </div>
          <div class="shade-text" (click)="selectCustom()" [ngClass]="{'selected': shadesCount === 4}">
           Custom
          </div>
          <div *ngIf="shadesCount > 0">
            <div class="line"></div>
            <div class="clients-list-scroll">
              <ng-container  *ngIf="!isCustumShade">
                <nb-radio-group class="shade-radio-label" *ngIf="shadesCount == 1" [(ngModel)]="selectedShade"
                (valueChange)="selectShade($event, '')">
                <nb-radio *ngFor="let shade of shades" [value]="shade.name">
                  {{shade.name}}
                </nb-radio>
              </nb-radio-group>
              <div *ngIf="shadesCount > 1" style="overflow-x: hidden;">
                <div class="select-option shade-text" (click)="getshades('Occlusal')">
                  Occlusal
                  <img src="../../../../assets/images/icons/down-arrow-select.svg" alt=""
                    [ngClass]="{'rotate-img': isOcclusalListOpen}">
                </div>
                <nb-radio-group class="shade-radio-label" *ngIf="isOcclusalListOpen" [(ngModel)]="selectedOcclusal"
                  (valueChange)="selectShade($event,'O')">
                  <nb-radio *ngFor="let shade of shades" [value]="shade.name">
                    {{shade.name}}
                  </nb-radio>
                </nb-radio-group>
                <div *ngIf="shadesCount == 3" class="select-option shade-text" (click)="getshades('Middle')">
                  Middle
                  <img src="../../../../assets/images/icons/down-arrow-select.svg" alt=""
                    [ngClass]="{'rotate-img': isMiddleListOpen}">
                </div>
                <nb-radio-group class="shade-radio-label" *ngIf="isMiddleListOpen" [(ngModel)]="selectedMiddle"
                  (valueChange)="selectShade($event,'M')">
                  <nb-radio *ngFor="let shade of shades" [value]="shade.name">
                    {{shade.name}}
                  </nb-radio>
                </nb-radio-group>
                <div class="select-option shade-text" (click)="getshades('Gingival')">
                  Gingival
                  <img src="../../../../assets/images/icons/down-arrow-select.svg" alt=""
                    [ngClass]="{'rotate-img': isGingivalListOpen}">
                </div>
                <nb-radio-group class="shade-radio-label" *ngIf="isGingivalListOpen" [(ngModel)]="selectedGingival"
                  (valueChange)="selectShade($event,'G')">
                  <nb-radio *ngFor="let shade of shades" [value]="shade.name">
                    {{shade.name}}
                  </nb-radio>
                </nb-radio-group>
              </div>
              </ng-container>


              <ng-container *ngIf="isCustumShade">
   
                <input [(ngModel)]="selectedShadesString" type="text" placeholder="Write shade and press ↵"
                (keyup.enter)="inputCustomShade()" class="shade-text-input">
              </ng-container>


            </div>


          </div>

        </div>


      </div>
    </div>
  </div>
  <div>

  </div>

</div>