import { Component } from '@angular/core';
import * as LR from '@uploadcare/blocks';
import type { UploadcareFile } from '@uploadcare/upload-client';
import { PACKAGE_VERSION } from '@uploadcare/blocks';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
LR.registerBlocks(LR);


type UploadcareBlocksFile = UploadcareFile & {
  cdnUrlModifiers: string | null;
};

  



  @Component({
    selector: 'ngx-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    
  })
  export class FileUploaderComponent  {
  cssSrc = `https://unpkg.com/@uploadcare/blocks@${PACKAGE_VERSION}/web/lr-file-uploader-regular.min.css`;
  files: File[] = [];

  constructor(private http: HttpClient) {}

  handleUploaderEvent(e: Event) {
    const { data: files } = (e as CustomEvent).detail;
        console.log(e);
        
  }
}