import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { finalize } from 'rxjs/operators';
import { LocationModel, WelcomeScreenSettingsModel, CompanyServiceProxy, LocationServiceProxy, WelcomeScreenShowingModel } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService } from '../../../@core/service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'ngx-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
  animations: [
    trigger('slowAnimate', [
      transition(':enter', [style({opacity: '0'}), animate(1000)]),
      transition(':leave', [style({opacity: '1'}), animate(1000, style({opacity: '0'}))]),
    ])
  ]
})
export class WelcomeModalComponent  implements OnInit {
  itemsPerRow: number = 3; 
  locationPrevLabel: string = 'Select Location';
  isEdit: boolean = false;
  isDisabEditPrevLabel: boolean = true
  searchParam: string = "";
  isLoader: boolean = false;
  locationList: LocationModel[] = [];
  reorderable: boolean = true;
  loadingIndicator: boolean = true;
  pageNumber: any;
  welcomeScreenModelDiscard: WelcomeScreenShowingModel = new WelcomeScreenShowingModel();
  welcomeScreenModel: WelcomeScreenShowingModel = new WelcomeScreenShowingModel();
  actionStatus: number = 1;
  user: any = {};
  constructor( 
    private _companyService: CompanyServiceProxy,
    public dialogRef: NbDialogRef<WelcomeModalComponent>,
    private _authenticationService: AuthenticationService,
  ) {
 
    let currentUser = this._authenticationService.currentUserValue;
     let helper = new JwtHelperService();
     this.user = helper.decodeToken(currentUser.accessToken);
 
  }
  changeStatusAction(type: any) {
    this.actionStatus = type;
  }
  selectLocation(location: any) {
    localStorage.setItem('selectLocation', JSON.stringify({id: location.locationId, name: location.locationName, logoUrl: location.locationLogo}));
    window.open('./pages/dashboard', '_self');
  }

  getWelcomeScreenSettings() {
    this._companyService.getWelcomeScreenbyRole().pipe(
      finalize(() => {
        this.isLoader = false;
      }))
    .subscribe(
      (result: WelcomeScreenShowingModel) => {
        this.welcomeScreenModelDiscard = JSON.parse(JSON.stringify(result));
        this.welcomeScreenModel = result;
        if (this.welcomeScreenModel.locationOrders.length == 1) {
          localStorage.setItem('selectLocation', JSON.stringify({id: this.welcomeScreenModel.locationOrders[0].locationId, name: this.welcomeScreenModel.locationOrders[0].locationName, logoUrl: this.welcomeScreenModel.locationOrders[0].locationLogo}));
          window.open('./pages/dashboard', '_self');
        }

        setTimeout(() => {
          const backdropElement = document.querySelector('.cdk-overlay-backdrop') as HTMLElement;
          if (backdropElement) {
            backdropElement.style.backgroundColor =  this.welcomeScreenModel?.background; // Example: darken the backdrop
          }
        });
      },
      (error: any) => { }
    )
  }
  discard() {
    this.actionStatus = 1;
    this.welcomeScreenModel = null;
    setTimeout(() => {
      this.welcomeScreenModel = this.welcomeScreenModelDiscard;
    }, 500);
   
  }

  close() {
    localStorage.setItem('selectLocation', JSON.stringify({id: this.welcomeScreenModel.locationOrders[0].locationId, name: this.welcomeScreenModel.locationOrders[0].locationName, logoUrl:  this.welcomeScreenModel.locationOrders[0].locationLogo}));
    window.open('./pages/dashboard', '_self');
  }
  ngOnInit(): void {
    this.getWelcomeScreenSettings();
   
  }

 

  changeToggleTypeVisble(e: any) {
    this.welcomeScreenModel.type = e[0];
    console.log('test', e);
    
  }
}
