import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Order3ShapeCustomFeeModel } from '../../../../shared/service-proxies/event-service-proxy';

@Component({
  selector: 'ngx-custom-fee-dialog',
  templateUrl: './custom-fee-dialog.component.html',
  styleUrls: ['./custom-fee-dialog.component.scss']
})
export class CustomFeeDialogComponent implements OnInit {
  currencySymbol = '£';
  @Input() name: string;
  @Input() amount: number; 
  @Output() result = new EventEmitter();
  
  data = new Order3ShapeCustomFeeModel();
  
  constructor(private dialogRef: NbDialogRef<CustomFeeDialogComponent>) { }

  ngOnInit(): void {
    if (this.name) {
      this.data.name = this.name;
    }
    if (this.amount) {
      this.data.amount = this.amount;
    }
  }
  close() {
    this.dialogRef.close();
  }
  submit() {
    this.dialogRef.close(this.data);
    this.dialogRef.close('submit');
  }
}
