
<div class=""  #locationClick>

<ng-select (mouseleave)="onSelectClick($event)" [loading]="isReceivedLoading" [markFirst]="true" [ngClass]="{'focus-select': !order.receivingOption}"
(mouseenter)="onShowDropdown()" #selectDropdownReceiving [loading]="false" placeholder="Select receiving source"
[closeOnSelect]="false" class="receiving-select-dropdown" [searchable]="false"     (open)="onDropdownOpened($event)" 
(close)="onDropdownClosed($event)" [clearable]="false" (change)="changeReceiving()"
[items]="receivingSettingsModelDto.receivingOptions | filterByReceivingType:  order.receivingType" bindLabel="name"
bindValue="name" [(ngModel)]="order.receivingOption">
<ng-template ng-label-tmp let-item="item">
   
    <div  class="receiving-label" *ngIf="item.type !== 'Analogue' && item.name !== 'notApplicable'">
        {{item.name}}
    </div>
    <div  class="receiving-label" *ngIf="item.name == 'notApplicable'">
        Not Applicable
    </div>
    <div class="receiving-label" *ngIf="item.type == 'Analogue'">
        <ng-container *ngFor="let val of receivingSettingsModelDto.receivingOptions">
            <ng-container *ngIf="val.count !== 0">
                <span>{{val.count + '_' + val.name}}&nbsp;</span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
<ng-template ng-header-tmp>
    <div class="receiving-header-select">
        <div class="receiving-header-title">
            Impression type
        </div>
        <div class="receiving-header-types">

            <div (mouseenter)="mouseSetterType('Digital')" [ngClass]="{'select': order.receivingType == 'Digital'}"
                (click)="selectReceiving('Digital')" *ngIf="receivingSettingsModelDto.isActiveDigital"
                class="receiving-type-item">{{receivingSettingsModelDto.digitalDisplayName}}</div>
            <div (mouseenter)="mouseSetterType('Analogue')"
                [ngClass]="{'select': order.receivingType == 'Analogue'}" (click)="selectReceiving('Analogue')"
                *ngIf="receivingSettingsModelDto.isActiveAnalogue" class="receiving-type-item">{{receivingSettingsModelDto.analogueDisplayName}}</div>
            <div (mouseenter)="mouseSetterType('notApplicable')"
                [ngClass]="{'select': order.receivingType == 'notApplicable'}"
                (click)="selectReceiving('notApplicable')" *ngIf="receivingSettingsModelDto.isActiveNotAppicable"
                class="receiving-type-item">{{receivingSettingsModelDto.notAppicableDisplayName}}</div>
        </div>
    </div>
</ng-template>



<ng-template ng-notfound-tmp let-searchTerm="searchTerm"  *ngIf="order.receivingType && order.receivingType == 'notApplicable'">
    <div class="ng-option disabled p-0">
        
    </div>
</ng-template>
<ng-template ng-loadingtext-tmp  *ngIf="order.receivingType && order.receivingType == 'notApplicable'">
    <div class="ng-option disabled p-0">
    </div>
</ng-template>

<ng-template  *ngIf="order.receivingType"  ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="receiving-option-item row w-100 m-0" *ngIf="order.receivingType == 'Digital'">
        <div class="col-2 p-0">
            <div class="radio-option">
                <div class="selected-radio"></div>
            </div>
        </div>
        <div class="col-10 p-0">
            <div class="received-select-label-text">
                {{ item['name'] }}
            </div>
        </div>
    </div>
    <div class="receiving-option-item row w-100 m-0 position-relative" *ngIf="order.receivingType == 'Analogue'">
        <div class="col-4 p-0 absolute-select-receiving">
            <div class="select-container ">

                <ng-select [loading]="isReceivedLoading" [(ngModel)]="item.count" id="test" [multiple]="false"
                    class="custom-receiving-select" [searchable]="false" [clearable]="false" bindLabel="name"
                    bindValue="id" [items]="countOfAnalog" appendTo=".receiving-select-dropdown">
                </ng-select>

            </div>
        </div>
        <div class="col-8 p-0">
            <div class="received-select-label-text analog-label-select">
                {{ item['name'] }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template  *ngIf="order.receivingType"  ng-footer-tmp>
    <div class="receiving-footer-select">
        <input [(ngModel)]="order.receivingText" type="text" placeholder="Write other items here.."
            class="receiving-text-input">
        <button (click)="onCloseDropdown()" shape="semi-round" fullWidth nbButton
            class="btn-shadow close-button-size" nbButton size="small" status="primary">
            Done
        </button>
    </div>
</ng-template>

</ng-select>
<div class="overlay-bg" *ngIf="isSelectOpened && !order.receivingOption">

</div>

</div>