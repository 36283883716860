<table class="w-100">
    <tbody>
        <tr *ngFor="let product of basketAutomationProducts">
            <td>
                {{product.quantity && product.quantity > 1 ? product.quantity + ' x' : ''}}
            </td>
            <td class="text-capitalize">
                {{product.sku}}
            </td>
            <td class="text-capitalize">
                {{product.productName}}
            </td>
            <td class="text-capitalize">
                {{product.product.subcategoryName}}
            </td>
            <td class="text-capitalize">
                {{product.materialName}}
            </td>
            <td class="text-capitalize">
                {{product.standardTierName}}
            </td>
            <td>
                {{product.product.price | currency:currencyCode:"symbol"}}
            </td>
            <td (click)="deleteProduct(product)">
                <img src="../../../../assets/images/icons/delete-x.svg" *ngIf="!required || basketAutomationProducts && basketAutomationProducts.length > 1" alt="">
            </td>
        </tr>
    </tbody>
</table>
<ng-container *ngIf="validate">
    <div *ngIf="required && !basketAutomationProducts || basketAutomationProducts.length === 0" class="invalid-feedback d-block">
        <div>
            Select at least one product
        </div>
    </div>
</ng-container>