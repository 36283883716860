import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbIconConfig, NbToastrService } from '@nebular/theme';
import { finalize } from 'rxjs/operators';
import { CountryModel, ProductServiceProxy, PriceListModel, CompanyModel, CompanyServiceProxy, DentistModel, DentistModelPagedListDto, LocationCreateModel, LocationRouteModel, LocationServiceProxy, PracticeCreateModel, PracticeModel, PracticeServiceProxy, ResultMessage, ShortUserModel, AuthServiceProxy, TeamFilter, PracticeCreateNewModel, PracticeAssignedUsersModel, BillingServiceProxy, LocationExportGroupList, LocationExportGroupModel } from '../../../../shared/service-proxies/event-service-proxy';
import { CreateCompanyModalComponent } from '../../company/create-company-modal/create-company-modal.component';
import { AddDoctorModalComponent } from '../../doctors/add-doctor-modal/add-doctor-modal.component';
import { DeleteModalApproveComponent } from '../../../@theme/helpers/delete-modal-approve/delete-modal-approve.component';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { PracticeGroupModalComponent } from '../../../@theme/helpers/practice-group-modal/practice-group-modal.component';
function createValidation(edit: any): ValidatorFn {
  if (edit) {
    return Validators.nullValidator;
  } else {
    return Validators.required
  }
}
@Component({
  selector: 'ngx-create-practice-modal',
  templateUrl: './create-practice-modal.component.html',
  styleUrls: ['./create-practice-modal.component.scss']
})
export class CreatePracticenModalComponent implements OnInit {
  @Input() locationId: any;
  @Input() companyId: any;
  @Input() practice: any;
  @Input() isEdit: any;
  @Input() isDoctorAlreadyExist: boolean;
  form: FormGroup;
  practiceModelDto: PracticeCreateNewModel = new PracticeCreateNewModel();
  dentistListDto: DentistModel[] = [];
  isLoading: boolean = false;
  isDentistListLoading: boolean = false;
  isPriceListLoading: boolean = false;
  isCountriesLoading: boolean = false;
  pricelistsDto: PriceListModel[];
  countries: CountryModel[] = [];

isPracticeGroup: boolean = false;


  states: any;
  billingStates: any;
  isSaveAsBilling: boolean = true;
  isSaveAsBillingAddress: boolean = true;
  IsAddress2: boolean = false;
  IsBillingAddress2: boolean = false;
  phoneNubmer: any;
  selectedCountryName: any;

  billingUsers: ShortUserModel[] = [];
  isBillingUserLoading: boolean = false;
  locationExportGroups: LocationExportGroupModel[] = [];
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _practiceService: PracticeServiceProxy,
    private _locationService: LocationServiceProxy,
    private _productService: ProductServiceProxy,
    private _authService: AuthServiceProxy,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private _billingService: BillingServiceProxy,
    protected dialogRef: NbDialogRef<CreatePracticenModalComponent>,

  ) { }
  resetForm() {
    this.form.reset();
  }

  setupDentistList(event: any) {
    if (event.status) {
      this.practiceModelDto.clients = event.data.map((x: PracticeAssignedUsersModel) => {
        let data: PracticeAssignedUsersModel = new PracticeAssignedUsersModel(); 
        data.userId = x.userId;
        data.isOnlyOwnOrdersAcess = x.isOnlyOwnOrdersAcess;
        data.isOnlyOwnPatientsAcess = x.isOnlyOwnPatientsAcess;
        return data
      });
      this.practiceModelDto.dentistId = this.practiceModelDto.clients[0].userId;
    }
  }




  getPracticeGroup() {
    this.isBillingUserLoading = true;
    this._locationService.getExportGroups(this.locationId).pipe(
      finalize(() => {
        this.isBillingUserLoading = false;
      }))
      .subscribe(
        (resp: LocationExportGroupList) => {
          this.locationExportGroups = resp.locationExportGroups
        },
        (error: any) => { }
      )
  }


  locationRouteListDto: LocationRouteModel[] = [];
  locationRouteListDtoTemp: LocationRouteModel[] = [];
  isLocationListLoading: boolean = false;
  totalItemsPracticeDentistList: any;
  getLocationRouteWhithLocationId() {

    this.isLocationListLoading = true;
    this._locationService.getLocationRoutesByLocationId(this.locationId).pipe(
      finalize(() => {
        this.isLocationListLoading = false;
      }))
      .subscribe(
        (resp: LocationRouteModel[]) => {
          this.locationRouteListDto = resp;
          this.locationRouteListDtoTemp = resp;
        },
        (error: any) => { }
      )
  }

  getLocationRouteSearch(e: any) {
    if (e)
      this.locationRouteListDto = this.locationRouteListDtoTemp.filter(
        (row) =>
          row.name
            ?.toLowerCase()
            .includes(e?.toLowerCase())
      );
    else
      this.locationRouteListDto = this.locationRouteListDtoTemp;
    this.totalItemsPracticeDentistList = this.locationRouteListDto.length;
  }
  getDentistListWithCompanyId(search: any) {
    this.isDentistListLoading = true;
    this._practiceService.getDentistsByCompanyId(this.companyId, 1, 15, search).pipe(
      finalize(() => {
        this.isDentistListLoading = false;
      }))
      .subscribe(
        (resp: DentistModelPagedListDto) => {
          this.dentistListDto = resp.items;
        },
        (error: any) => { }
      )

  }

  getLocationUsers() {
    this.isBillingUserLoading = true;
    this._authService.getUsersAssignedToLocation(this.locationId, TeamFilter.All).pipe(
      finalize(() => {
        this.isBillingUserLoading = false;
      }))
      .subscribe(
        (resp: ShortUserModel[]) => {
          this.billingUsers = resp;
        });
  }

  getLocationCountryCode() {
    this._locationService.getCountryByLocationId(this.practiceModelDto.locationId).pipe(
      finalize(() => {
      }))
      .subscribe(
        (resp: ResultMessage) => {
            this.practiceModelDto.country = resp.message;
            this.changePhoneNumCountry();
        });
  }


  ngOnInit(): void {
    
    

    this.practiceModelDto.locationId = this.locationId;
  
    if (this.isEdit) {
      this.practiceModelDto = this.practice;
      this.phoneNubmer = this.practiceModelDto.phone;
      this.chectCheckboxes();
    } else {
      this.getDentistListWithCompanyId('');
    }
    this.getLocationRouteWhithLocationId();
    this.getPriceLists(this.locationId);
    this.loadCountries();
    this.getLocationUsers();
    this.getPracticeGroup();
  
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      billingEmail: [null],
      country: [null, [Validators.required,]],
      address: [null, [Validators.required,]],
      address2: [null],
      town: [null],
      city: [null],
      postCode: [null],
      phone: [null, [Validators.required,]],
      locationRouteId: [null],
      // dentistId: [null, createValidation(this.isDoctorAlreadyExist)],
      priceListId: [null],
      state: [null],
      billingCountry: [null],
      billingAddress: [null],
      billingAddress2: [null],
      billingCity: [null],
      billingPostCode: [null],
      billingState: [null],
      billingOwnerId: [null],
      idhPracticeNumber: [null],
      locationExportGroupId: [null]
    });
   

    if ( this.practiceModelDto.billingEmail !==  this.practiceModelDto.email) {
      this.isSaveAsBilling = false;
      this.isSaveAsBillingAddress = false;
    }

  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  close() {
    this.dialogRef.close(false);
  }
  addCompany() {
    this.dialogRef.close(true);

  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  onSubmit() {
    if (this.form.valid) {
      if (!this.practiceModelDto.dentistId) {
        const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
        this.toastrService.danger('', 'Please assign one or more clients for practice', { icon: iconConfig });
      }
      this.practiceModelDto.phone = this.phoneNubmer.e164Number;
      this.isLoading = true;
      this.checkBilling();
      this._practiceService.createNew(this.practiceModelDto).pipe(
        finalize(() => {
          this.isLoading = false;
        })).subscribe(
          (resp) => {
            this.dialogRef.close(true);
          },
          (error: any) => {

          }
        )
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  onUpdate() {
    if (this.form.valid) {
      this.checkBilling();
      let updatePractice: PracticeModel = new PracticeModel();
      updatePractice.id = this.practice.id;
      updatePractice.locationId = this.practiceModelDto.locationId;
      updatePractice.dentistId = this.practiceModelDto.dentistId;
      updatePractice.name = this.practiceModelDto.name;
      updatePractice.email = this.practiceModelDto.email;
      updatePractice.country = this.practiceModelDto.country;
      updatePractice.address = this.practiceModelDto.address;
      updatePractice.address2 =  this.practiceModelDto.address2;
      updatePractice.town =  this.practiceModelDto.town;
      updatePractice.city =  this.practiceModelDto.city;
      updatePractice.postCode =  this.practiceModelDto.postCode;
      updatePractice.phone =  this.phoneNubmer.e164Number;
      // updatePractice.phone =  this.practiceModelDto.phone;
      updatePractice.billingEmail = this.practiceModelDto.billingEmail;
      updatePractice.locationRouteId = this.practiceModelDto.locationRouteId;
      updatePractice.priceListId = this.practiceModelDto.priceListId;

      updatePractice.billingAddress = this.practiceModelDto.billingAddress;
      updatePractice.billingAddress2 = this.practiceModelDto.billingAddress2;
      updatePractice.billingCity = this.practiceModelDto.billingCity;
      updatePractice.billingCountry = this.practiceModelDto.billingCountry;
      updatePractice.billingState = this.practiceModelDto.billingState;
      updatePractice.billingPostCode = this.practiceModelDto.billingPostCode;
      updatePractice.billingOwnerId = this.practiceModelDto.billingOwnerId;
      updatePractice.state = this.practiceModelDto.state;


      updatePractice.locationExportGroupId = this.practiceModelDto.locationExportGroupId;
      updatePractice.idhPracticeNumber = this.practiceModelDto.idhPracticeNumber;
      

      this._practiceService.update(updatePractice).subscribe(
        (resp) => {
          this.dialogRef.close(true);
        },
        (error: any) => {
          this.dialogRef.close(false);
        }
      )
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  checkBilling() {

    if (this.isSaveAsBilling) {
      this.practiceModelDto.billingEmail = this.practiceModelDto.email;
    }

    if (this.isSaveAsBillingAddress) {
      this.practiceModelDto.billingCountry = this.practiceModelDto.country;
      this.practiceModelDto.billingState = this.practiceModelDto.state;
      this.practiceModelDto.billingAddress = this.practiceModelDto.address;
      this.practiceModelDto.billingAddress2 = this.practiceModelDto.address2;
      this.practiceModelDto.billingCity = this.practiceModelDto.city;
      this.practiceModelDto.billingPostCode = this.practiceModelDto.postCode;
      this.practiceModelDto.billingOwnerId = this.practiceModelDto.billingOwnerId;
    }
    if (this.practiceModelDto.billingCountry == null) {
      this.practiceModelDto.billingCountry = this.practiceModelDto.country;
    }

  }

  getPriceLists(locationId: any) {
    if (!locationId) {
      return;
    }
    this.isPriceListLoading = true;
    this._productService.getPriceLists(locationId).pipe(
      finalize(() => {
        this.isPriceListLoading = false;
      })
    ).subscribe(
      (resp) => {
        if (resp) {
          this.pricelistsDto = resp;
        }
      },
      (error: any) => {
        console.error('Error fetching pricelists:', error);
      }
    );
  }

  toggleSaveAsBilling() {
    this.isSaveAsBilling = !this.isSaveAsBilling;
  }
  toggleSaveAsBillingAddress() {
    this.isSaveAsBillingAddress = !this.isSaveAsBillingAddress;
  }

  toggleIsBillingAddress2() {
    this.IsBillingAddress2 = true;
  }

  toggleIsAddress2() {
    this.IsAddress2 = true;
  }


  chectCheckboxes() {

    if (this.practiceModelDto.billingAddress2 != null) {
      this.toggleIsBillingAddress2();
    }
    if (this.practiceModelDto.address2 != null) {
      this.toggleIsAddress2();
    }
    this.onCountryChange(this.practiceModelDto.country, false);
    if (this.practiceModelDto.billingCountry != null) {
      this.onBillingCountryChange(this.practiceModelDto.billingCountry, false);
    }
  }

  loadCountries() {
    this.isCountriesLoading = true;
    this._locationService.getAllCountries().pipe(
      finalize(() => {
        this.isCountriesLoading = false;
      }))
      .subscribe(
        (result: CountryModel[]) => {
          this.countries = result;
          if (this.isEdit ) {
            this.changePhoneNumCountry();
          } else {
            
            this.getLocationCountryCode();
          }
        },
        (error: any) => {
        }
      )
  }

  onCountryChange(countryName: string, isChange: boolean) {
    if (isChange) {
      this.states = null;
      this.practiceModelDto.state = null;
      this.changePhoneNumCountry();
    }
    switch (countryName) {
      case "Ireland":
        this.states = Object.keys(this.irelandCountiesDictionary).map(key => {
          return { abbreviation: key, name: this.irelandCountiesDictionary[key] };
        });
        break;
      case "United States":
        this.states = Object.keys(this.usStatesDictionary).map(key => {
          return { abbreviation: key, name: this.usStatesDictionary[key] };
        });
        break;
      case "Canada":
        this.states = Object.keys(this.canadianProvincesDictionary).map(key => {
          return { abbreviation: key, name: this.canadianProvincesDictionary[key] };
        });
        break;
      case "Australia":
        this.states = Object.keys(this.australianStatesDictionary).map(key => {
          return { abbreviation: key, name: this.australianStatesDictionary[key] };
        });
        break;

      case "United Kingdom":
        this.states = Object.keys(this.ukRegionsDictionary).map(key => {
          return { abbreviation: key, name: this.ukRegionsDictionary[key] };
        });
        break;
      case "Belgium":
        this.states = Object.keys(this.belgiumProvincesDictionary).map(key => {
          return { abbreviation: key, name: this.belgiumProvincesDictionary[key] };
        });
        break;
      default:
        break;
    }


  }

  onBillingCountryChange(countryName: string, isChange: boolean) {
    if (isChange) {
      this.billingStates = null;
      this.practiceModelDto.billingState = null;
    }

    switch (countryName) {
      case "United States":
        this.billingStates = Object.keys(this.usStatesDictionary).map(key => {
          return { abbreviation: key, name: this.usStatesDictionary[key] };
        });
        break;
      case "Canada":
        this.billingStates = Object.keys(this.canadianProvincesDictionary).map(key => {
          return { abbreviation: key, name: this.canadianProvincesDictionary[key] };
        });
        break;
      case "Australia":
        this.billingStates = Object.keys(this.australianStatesDictionary).map(key => {
          return { abbreviation: key, name: this.australianStatesDictionary[key] };
        });
        break;
      case "Ireland":
        this.billingStates = Object.keys(this.irelandCountiesDictionary).map(key => {
          return { abbreviation: key, name: this.irelandCountiesDictionary[key] };
        });
        break;
      case "United Kingdom":
        this.billingStates = Object.keys(this.ukRegionsDictionary).map(key => {
          return { abbreviation: key, name: this.ukRegionsDictionary[key] };
        });
        break;
      case "Belgium":
        this.billingStates = Object.keys(this.belgiumProvincesDictionary).map(key => {
          return { abbreviation: key, name: this.belgiumProvincesDictionary[key] };
        });
        break;
      default:
        break;
    }
  }

  createDentist() {
    this.dialogService.open(AddDoctorModalComponent, { context: { companyId: this.companyId, locationId: this.practiceModelDto.locationId } })
      .onClose.subscribe((resp) => {
        if (resp) {
          const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
          this.toastrService.success('', "Doctor successfully created", { icon: iconConfig });
          this.getDentistListWithCompanyId('');
        }
      });
  }
  createGroup() {
    this.dialogService.open(PracticeGroupModalComponent, {
      context: {
        locationId: this.locationId
      }
    })
      .onClose.subscribe((resp) => {
        if (resp) {
            this.getPracticeGroup();
        }
      });
  }
  deletePractice() {
    this.dialogService.open(DeleteModalApproveComponent, {
      context: {
        headTitle: 'Are you sure ?',
        title: `Delete Practice ${this.practice.name}`,
        btnText: 'Delete',
        btnClose: 'Close'
      }
    })
      .onClose.subscribe((resp) => {
        if (resp) {
          this._practiceService.deletePracticeById(this.practice.id)
            .subscribe(
              (resp: ResultMessage) => {
                const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
                this.toastrService.success('', "Practice successfully delete", { icon: iconConfig });
                this.dialogRef.close(true);
              },
              (error: any) => {
                const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
                this.toastrService.danger('', 'Not delete', { icon: iconConfig });
              }
            )
        }
      });
  }

  canadianProvincesDictionary: { [key: string]: string } = {
    'AB': 'Alberta',
    'BC': 'British Columbia',
    'MB': 'Manitoba',
    'NB': 'New Brunswick',
    'NL': 'Newfoundland and Labrador',
    'NS': 'Nova Scotia',
    'NT': 'Northwest Territories',
    'NU': 'Nunavut',
    'ON': 'Ontario',
    'PE': 'Prince Edward Island',
    'QC': 'Quebec',
    'SK': 'Saskatchewan',
    'YT': 'Yukon'
  };

  australianStatesDictionary: { [key: string]: string } = {
    'ACT': 'Australian Capital Territory',
    'NSW': 'New South Wales',
    'NT': 'Northern Territory',
    'QLD': 'Queensland',
    'SA': 'South Australia',
    'TAS': 'Tasmania',
    'VIC': 'Victoria',
    'WA': 'Western Australia'
  };

  usStatesDictionary: { [key: string]: string } = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming'
  };


  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }


  getCountryName(countryCode: string): string {
    for (const countryName in CountryISO) {
      if (CountryISO[countryName] === countryCode) {
        return countryName;
      }
    }
    return "Unknown"; // Return "Unknown" if the country code is not found
  }


  changePhoneNumCountry() {
    let code = this.countries.find(x => x.countryName == this.practiceModelDto.country).countryCode;
    this.selectedCountryName = code.toLowerCase();
  }

  ukRegionsDictionary: { [key: string]: string } = {
    'ENG': 'England',
    'NIR': 'Northern Ireland',
    'SCT': 'Scotland',
    'WLS': 'Wales'
  };

  irelandCountiesDictionary: { [key: string]: string } = {
    'CW': 'Carlow',
    'CN': 'Cavan',
    'CE': 'Clare',
    'CO': 'Cork',
    'DL': 'Donegal',
    'D': 'Dublin',
    'G': 'Galway',
    'KY': 'Kerry',
    'KE': 'Kildare',
    'KK': 'Kilkenny',
    'LS': 'Laois',
    'LM': 'Leitrim',
    'LK': 'Limerick',
    'LD': 'Longford',
    'LH': 'Louth',
    'MO': 'Mayo',
    'MH': 'Meath',
    'MN': 'Monaghan',
    'OY': 'Offaly',
    'RN': 'Roscommon',
    'SO': 'Sligo',
    'TA': 'Tipperary',
    'WD': 'Waterford',
    'WH': 'Westmeath',
    'WX': 'Wexford',
    'WW': 'Wicklow'
  };

  belgiumProvincesDictionary: { [key: string]: string } = {
    'VAN': 'Antwerp',
    'WBR': 'Brabant Wallon',
    'BRU': 'Brussels-Capital Region',
    'VOV': 'East Flanders',
    'VBR': 'Flemish Brabant',
    'WHT': 'Hainaut',
    'WLG': 'Liège',
    'VLG': 'Limburg',
    'WLX': 'Luxembourg',
    'WNA': 'Namur',
    'VLI': 'Limburg',
    'WVL': 'West Flanders'
  };



  archivePractice() {
    this.dialogService.open(DeleteModalApproveComponent, {
      context: {
        headTitle: 'Are you sure ?',
        title: ``,
        btnText: 'Remove',
        btnClose: 'Close'
      }
    })
      .onClose.subscribe((resp) => {
        if (resp) {
            this._practiceService.archivePracticeById(this.practice.id).pipe(
            finalize(() => {
            }))
            .subscribe(
              (resp: ResultMessage) => {
                if (resp.result) {
                  const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
                  this.toastrService.success('', "Practice successfully archived", { icon:iconConfig});
                  this.dialogRef.close(true);
                } else {
                  const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
                  this.toastrService.danger('',resp.message, { icon:iconConfig}); 
                }
              },
              (error: any) => { 
                const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
                this.toastrService.danger('','Not archive', { icon:iconConfig}); 
              }
            )
        }
      });
  }
  deArchivePractice() {
    this.dialogService.open(DeleteModalApproveComponent, {
      context: {
        headTitle: 'Are you sure ?',
        title: ``,
        btnText: 'Unarchive',
        btnClose: 'Close'
      }
    })
      .onClose.subscribe((resp) => {
        if (resp) {
            this._practiceService.unarchivePracticeById(this.practice.id).pipe(
            finalize(() => {
            }))
            .subscribe(
              (resp: ResultMessage) => {
                if (resp.result) {
                  const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
                  this.toastrService.success('', "Patient successfully Unarchived", { icon:iconConfig});
                  this.dialogRef.close(true);
                } else {
                  const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
                  this.toastrService.danger('',resp.message, { icon:iconConfig}); 
                }
              },
              (error: any) => { 
                const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
                this.toastrService.danger('','Not archive', { icon:iconConfig}); 
              }
            )
        }
      });
  }
}
