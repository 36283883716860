import { FieldComparisonOperator } from "../../../shared/service-proxies/event-service-proxy";

export class FieldComparisonOperatorModel {
    displayName: string;
    value: FieldComparisonOperator;
    referenceType: string | null;
    compiledView: string;

    constructor(displayName: string, value: FieldComparisonOperator, referenceType: string | null = null) {
        this.displayName = displayName;
        this.value = value;
        this.referenceType = referenceType;
        this.compiledView = (this.referenceType ? `(${this.referenceType}) ` : '') + this.displayName;
    }
}

export const fieldComparisonOperators: FieldComparisonOperatorModel[] = [
    new FieldComparisonOperatorModel('Contains', FieldComparisonOperator.Contains, 'Text'),
    new FieldComparisonOperatorModel('Does not contain', FieldComparisonOperator.DoesNotContain, 'Text'),
    new FieldComparisonOperatorModel('Exactly matches', FieldComparisonOperator.Equals, 'Text'),
    new FieldComparisonOperatorModel('Does not exactly match', FieldComparisonOperator.DoesNotEqual, 'Text'),
    new FieldComparisonOperatorModel('Is in', FieldComparisonOperator.IsIn, 'Text'),
    new FieldComparisonOperatorModel('Is not in', FieldComparisonOperator.IsNotIn, 'Text'),
    new FieldComparisonOperatorModel('Starts with', FieldComparisonOperator.StartsWith, 'Text'),
    new FieldComparisonOperatorModel('Does not start with', FieldComparisonOperator.DoesNotStartWith, 'Text'),
    new FieldComparisonOperatorModel('Ends with', FieldComparisonOperator.EndsWith, 'Text'),
    new FieldComparisonOperatorModel('Greater than', FieldComparisonOperator.GreaterThan, 'Number'),
    new FieldComparisonOperatorModel('Less than', FieldComparisonOperator.LessThan, 'Number'),
    new FieldComparisonOperatorModel('Equals', FieldComparisonOperator.NumberEquals, 'Number'),
    new FieldComparisonOperatorModel('Exists', FieldComparisonOperator.Exists),
    new FieldComparisonOperatorModel('Does not exist', FieldComparisonOperator.DoesNotExist),
];

export function noValueOperator(operator: FieldComparisonOperator) : boolean {
    return operator === FieldComparisonOperator.Exists || operator == FieldComparisonOperator.DoesNotExist;
}

export function operatorRequiresNumber(operator: FieldComparisonOperator) : boolean {
    return [FieldComparisonOperator.GreaterThan, FieldComparisonOperator.LessThan, FieldComparisonOperator.NumberEquals]
        .includes(operator);
}