import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { log } from 'console';
import { NbIconConfig, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-shared-multi-shade-selection',
  templateUrl: './shared-multi-shade-selection.component.html',
  styleUrls: ['./shared-multi-shade-selection.component.scss']
})
export class SharedMultiShadeSelectionComponent implements OnInit, OnChanges {
  @Input() shades: any;
  @Input() selectedShades: any;
  @Output() newItemEventShade = new EventEmitter<any>();
  showMultiShadeSelect: boolean = false;
  shadesCount: number;
  isOcclusalListOpen: boolean = false;
  isMiddleListOpen: boolean = false;
  isGingivalListOpen: boolean = false;
  selectedOcclusal: string;
  selectedMiddle: string;
  selectedGingival: string;
  selectedShade: string;
  selectedShadesString: string;
  shadesArray: string[] = [];
  isNotEnoughData: boolean = false;
  constructor() { }

  ngOnInit(): void {
    if (this.selectedShades) {
      this.selectedShadesString = this.selectedShades;
        let shades = this.selectedShades.split(',');
        this.shadesCount = shades.length;
        if (this.shadesCount === 1) {
            this.selectedShade = shades[0].trim();
        } else {
            shades.forEach(shade => {
                if (shade.includes('(O)')) {
                    this.selectedOcclusal = shade.split('(O)')[0].trim();
                }
                if (shade.includes('(G)')) {
                    this.selectedGingival = shade.split('(G)')[0].trim();
                }
                if (shade.includes('(M)')) {
                    this.selectedMiddle = shade.split('(M)')[0].trim();
                }
            });
        }
    }
    else{
      this.selectedShadesString = "";
      this.shadesCount = 0;
      this.shadesArray = [];
    }
}

ngOnChanges(changes: SimpleChanges): void {
  if (changes.shades || changes.selectedShades) {
    this.ngOnInit(); 
  }
}
openDropdownHover(numShades: number): void {
  if ( this.shadesCount == 0) {
    this.shadesCount = numShades;
    this.selectedShadesString = "";
    this.shadesArray = [];
    this.selectedOcclusal = "";
    this.selectedMiddle = "";
    this.selectedGingival = "";
    this.updateEmitEmitter();
  }
}

  openDropdown(numShades: number): void {
    this.isCustumShade = false;
    if (numShades == this.shadesCount) {
      this.selectedShade = null;
      this.shadesCount = 0;
      return 
    } else {
      this.selectedShade = null;
      this.shadesCount = numShades;
      this.selectedShadesString = "";
      this.shadesArray = [];
      this.selectedOcclusal = "";
      this.selectedMiddle = "";
      this.selectedGingival = "";
      this.updateEmitEmitter();
    }
   
  }

  getshades(text: string) {
    switch (text) {
        case 'Occlusal':
            this.isOcclusalListOpen = !this.isOcclusalListOpen;
            break;
        case 'Middle':
            this.isMiddleListOpen = !this.isMiddleListOpen;
            break;
        case 'Gingival':
            this.isGingivalListOpen = !this.isGingivalListOpen;
            break;
        default:
            break;
    }
}

selectShade(event: any, suffix: string) {

  let selectedShadeText = '';
  const shade = event;
  if(suffix == ""){
    this.selectedShadesString = shade;
  }
   else if (suffix === 'O') {
      this.shadesArray[0] = `${shade}(O)`;
      this.isOcclusalListOpen = false;
  } else if (suffix === 'M') {
      this.shadesArray[1] = `${shade}(M)`;
      this.isMiddleListOpen = false;
  } else if (suffix === 'G') {
    if(this.shadesCount == 2)
      this.shadesArray[1] = `${shade}(G)`;
    else
    this.shadesArray[2] = `${shade}(G)`;

      this.isGingivalListOpen = false;
  }

  if (suffix !== "") {
    selectedShadeText = this.shadesArray.join(', ');
    this.selectedShadesString = selectedShadeText;
}
  this.verifyShades()
  this.checkContent();
}

closeComponent(){
  this.showMultiShadeSelect = false;
  this.verifyShades();
}

updateEmitEmitter() {
  let result: any = {
    status: !this.isNotEnoughData,
    data: this.selectedShadesString
  }
  this.newItemEventShade.emit(result)
}

checkContent() {
  const filteredArray = this.shadesArray.filter(value => value !== 'empty');
  if(this.shadesCount === 1){
    this.closeComponent();
  }  
  else if ((this.shadesCount === 2 && filteredArray.length === 2) || 
  (this.shadesCount === 3 && filteredArray.length === 3)) {
this.closeComponent();
}
}

verifyShades() {
  if (this.selectedShadesString) {
    this.isNotEnoughData = false;
  }
  if (this.shadesArray.length > 0) {
    const filteredArray = this.shadesArray.filter(value => value !== 'empty');
    if (this.shadesCount > 1 && filteredArray.length < this.shadesCount) {
      this.isNotEnoughData = true;
      
    } else {
      this.isNotEnoughData = false;
    }
  } 

  this.updateEmitEmitter();
}

selectTBD(){
  this.isCustumShade = false;
  this.shadesCount = 0;
  this.selectedShadesString = "TBD";
  this.shadesArray = [];
  this.isNotEnoughData = false;
  this.closeComponent();
}
isCustumShade: boolean = false;
selectCustom() {
  this.isCustumShade = !this.isCustumShade
  this.shadesCount = 4;
}
// let result: any = {
//   status: !this.isNotEnoughData,
//   data: this.selectedShadesString
// }
// this.newItemEventShade.emit(result)
// }
  inputCustomShade() {
    let result: any = {
      status: !this.isNotEnoughData,
      data: this.selectedShadesString
    }
    this.newItemEventShade.emit(result);
    this.closeComponent();
  }
}
