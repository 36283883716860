import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
    selector: 'ngx-camera-snapshot',
    templateUrl: './camera-snapshot.component.html',
    styleUrls: ['./camera-snapshot.component.scss']
})
export class CameraSnapshotComponent implements OnInit, OnDestroy {
    activeSettings: boolean = false;
    selectedCamera: any;
    cameraDevice: any[] = [];
    public photo: any;
    @Output()
    public pictureTaken = new EventEmitter<WebcamImage>();

    public showWebcam = true;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        // width: {ideal: 1024},
        // height: {ideal: 576}
    };
    public errors: WebcamInitError[] = [];

    private trigger: Subject<void> = new Subject<void>();
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

    private mediaStream: MediaStream | null = null;

    public ngOnInit(): void {
        this.start();
    }

    public ngOnDestroy(): void {
        this.stopCamera();
    }

    public start(): void {
        WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
                this.cameraDevice = mediaDevices;
            });

        navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
            this.mediaStream = stream;
        });
    }

    public stopCamera(): void {
        if (this.mediaStream) {
            this.mediaStream.getTracks().forEach(track => track.stop());
        }
    }

    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        this.nextWebcam.next(directionOrDeviceId);
    }

    public savePhoto(): void {
        this.pictureTaken.emit(this.photo);
        setTimeout(() => {
            this.photo = null;
        }, 200);
    }

    public handleImage(webcamImage: WebcamImage): void {
        console.info('received webcam image', webcamImage);
        this.photo = webcamImage;
    }
    public onCameraChange(deviceId: string): void {
        console.log('Camera changed to: ' + deviceId);
        this.cameraWasSwitched(deviceId);
        this.showNextWebcam(deviceId);
    }
    public cameraWasSwitched(deviceId: string): void {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
        this.selectedCamera = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }
}
