<nb-card class="modal-card" [nbSpinner]="isPrintingDone">
    <nb-card-header class="position-relative">
        <div class="modal-title">
            Manual invoice generation
        </div>
        <div class="close-modal-icon" (click)="close()">
            <img src="../../../../assets/images/icons/cancel.svg" alt="">
        </div>
        <div class="line"></div>
    </nb-card-header>



    <nb-card-body class="products-manual-invoice">
        <div class="row w-100 m-0 justify-content-center">
            <div class="col-12">
                <div class="table-of-product">
                    <div (click)="routeToOrderEdit(orderDto)" class="add-product"> Edit product selection </div>
                    <table class="table-base">
                        <tbody>
                            <tr>
                                <th class="thead sm">Product</th>
                                <th class="thead sm">Material</th>
                                <th class="thead sm">Standard</th>
                                <th class="thead sm">Shade</th>
                                <th class="thead sm text-center">Teeth/Arch</th>
                                <th class="thead sm text-center">Price</th>
                            </tr>
                            <ng-container *ngFor="let product of orderDto.orderProducts | ngxFilter:'isAddon':false">
                                <ng-container *ngIf="!product.isInvoiced">
                                    <tr style="cursor: pointer;" (click)="selectProduct(product)">
                                        <td class="tbody sm">
                                            {{product.productName}}
                                            <br>
                                            <small
                                                class="category-label">{{getCurrentNameCategory(product.product.categoryId)}}</small>
                                        </td>
                                        <td class="tbody sm">{{product.materialName}}</td>
                                        <td class="tbody sm">{{product.standardTierName}}</td>
                                        <td class="tbody sm">{{product.shade? product.shade : '-'}}
                                            <br>
                                            <small class="category-label">{{product.shade? product.shadeSystem :
                                                ''}}</small>
                                        </td>
                                        <td class="tbody sm text-center">{{product.teeth?
                                            convertTeethNotation(orderDto.location.teethType, product.teeth) :
                                            product.arch}}</td>
                                        <td class="tbody sm text-center">
                                            {{product.itemTmp | currency:orderDto?.location?.currency:"symbol" }}
                                        </td>
                                    </tr>

                                    <tr *ngIf="product.implantSpecification">
                                        <td colspan=6>
                                            <div class="implant-spec border-top-el">
                                                <div class="body-content">
                                                    <small class="category-label">Implant Scanbody</small>
                                                    {{product.implantScanbody}}
                                                </div>
                                                <div class="body-content"> <small class="category-label">Implant
                                                        Text</small>
                                                    {{product.implantScanbodyText}}
                                                </div>
                                                <div class="body-content"> <small class="category-label">Implant
                                                        Specification</small>
                                                    {{product.implantSpecification}}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>

                            <tr *ngFor="let product of orderDto.orderCustomFees">
                                <td class="tbody sm">{{product.name}}</td>
                                <td class="tbody sm">N/A</td>
                                <td class="tbody sm">N/A</td>
                                <td class="tbody sm">-</td>
                                <td class="tbody sm"> </td>
                                <td class="tbody sm">
                                    {{product.itemTmp | currency:orderDto?.location?.currency:"symbol" }}
                                </td>
                            </tr>

                            <tr>
                                <td colspan=4></td>
                                <td colspan=1>
                                    <div class="total-label">Total</div>
                                </td>
                                <td colspan=1>
                                    <div class="total-price text-center">
                                        {{price | currency:orderDto?.location?.currency:"symbol" }}

                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <div class="row w-100 m-0 justify-content-center">
            <div class="col-12">
                <div class="type-of-print">
                    <div class="type-print-title">
                        Print documents
                    </div>
                    <div class="print-list">
                        <div class="d-flex align-items-center">
                            <nb-checkbox class="d-flex align-items-center" [(ngModel)]="isLabDocket"
                                (checkedChange)="selectProduct($event)"></nb-checkbox>

                            <div class="item-title-select">Lab docket</div>
                        </div>
                        <div class="d-flex align-items-center">
                            <nb-checkbox class="d-flex align-items-center" [(ngModel)]="isInvoice"
                                (checkedChange)="selectProduct($event)"></nb-checkbox>
                            <div class="item-title-select">Invoice</div>
                        </div>
                        <div class="d-flex align-items-center">
                            <nb-checkbox class="d-flex align-items-center" [(ngModel)]="patientStatment"
                                (checkedChange)="selectProduct($event)"></nb-checkbox>
                            <div class="item-title-select">Patient statement</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </nb-card-body>

    <nb-card-footer>
        <div class="line"></div>
        <div class="row w-100 m-0 justify-content-center">
            <div class="col-12">
                <div class="close-modal-container">
                    <button shape="semi-round" (click)="close()" class="btn-shadow mr-2 close-button-size" nbButton
                        size="small" status="basic">
                        {{isGenerated ? "Close" : "Cancel"}}
                    </button>
                    <button *ngIf="!isGenerated" shape="semi-round" (click)="onSubmit()" nbButton status="primary"
                        size="small" class="save-button-size" [disabled]="isLoading">
                        Generate
                    </button>
                    <button *ngIf="isGenerated" shape="semi-round" (click)="onPrint()" nbButton status="primary"
                        [disabled]="!isLabDocket && !isInvoice &&  !patientStatment" size="small"
                        class="save-button-size">
                        Print
                    </button>
                </div>
            </div>
        </div>

    </nb-card-footer>
</nb-card>