<ng-container *ngFor="let group of rules?.predicateGroups; let i = index">
    <label class="input-label mt-2 mb-0" *ngIf="i > 0">Or</label>
    <ng-container *ngFor="let predicate of group.predicates; let k = index">
        <label class="input-label mt-2 mb-0" *ngIf="k > 0">And</label>
        <div class="d-flex mt-1">
            <div class="d-flex flex-grow-1">
                <div class="w-33 pr-1">
                    <ng-select appendTo="body" placeholder="Choose field" [(ngModel)]="predicate.field"
                        [searchable]="false" [clearable]="true">
                        <ng-option *ngFor="let ruleField of ruleFields" [value]="ruleField?.fieldPath">
                            {{ruleField?.fieldName}}</ng-option>
                    </ng-select>
                    <div *ngIf="validate && !predicate.field && (required || !isDefaultRuleset(rules))" class="invalid-feedback d-block">
                        <div>Please fill out this field.
                        </div>
                    </div>
                </div>
                <div class="w-33 px-1">
                    <ng-select appendTo="body" placeholder="Choose condition"
                        [(ngModel)]="predicate.fieldComparisonOperator" [searchable]="false" [clearable]="true">
                        <ng-option *ngFor="let fieldComparisonOperator of fieldComparisonOperators"
                            [value]="fieldComparisonOperator.value">{{fieldComparisonOperator.compiledView}}</ng-option>
                    </ng-select>
                    <div *ngIf="validate && !predicate.fieldComparisonOperator && (required || !isDefaultRuleset(rules))" class="invalid-feedback d-block">
                        <div>Please fill out this field.
                        </div>
                    </div>
                </div>
                <div class="w-33 pl-1" *ngIf="!checkOperatorDoesNotNeedValue(predicate?.fieldComparisonOperator)">
                    <input nbInput fullWidth
                        fieldSize="small" placeholder="Enter text or insert data" [(ngModel)]="predicate.value"
                        type="{{checkOpratorRequiresNumber(predicate?.fieldComparisonOperator) ? 'number' : ''}}" />
                    <div *ngIf="validate && !predicate.value && (required || !isDefaultRuleset(rules))" class="invalid-feedback d-block">
                        <div>Please fill out this field.
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="allowRuleDelete()" (click)="removeCondition(predicate, group)" shape="semi-round"
                nbButton status="basic" size="small" class="btn-remove-condition p-0">
                <nb-icon class="custom-icon" pack="great-pack" icon="trash-icon-greatlab-sizeless"></nb-icon>
            </button>
        </div>
    </ng-container>
    <button (click)="addCondition(group)" shape="semi-round" nbButton status="basic" size="small"
        class="btn-add-condition d-flex mt-3">
        <nb-icon class="custom-icon" pack="great-pack" icon="plus-blue"></nb-icon>
        And
    </button>
</ng-container>
<button (click)="addCondition(rules)" shape="semi-round" nbButton status="basic" size="small"
    class="btn-add-condition d-flex">
    <nb-icon class="custom-icon" pack="great-pack" icon="plus-blue"></nb-icon>
    Or
</button>