import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { finalize } from 'rxjs/operators';
import { CompanyModel, CompanyServiceProxy, FileParameter, LocationCreateModel, LocationModel, LocationServiceProxy, CurrencyModel, CountryModel, DateFormatModel } from '../../../../shared/service-proxies/event-service-proxy';
import { CreateCompanyModalComponent } from '../../company/create-company-modal/create-company-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { getTeethChartingSystems, TeethChartingSystem } from '../../../@core/models/TeethType';
import { getTeethShadeSystems, TeethShadeSystem } from '../../../@core/models/TeethShadeSystem ';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'ngx-create-location-modal',
  templateUrl: './create-location-modal.component.html',
  styleUrls: ['./create-location-modal.component.scss']
})
export class CreateLocationModalComponent  implements OnInit {
  @Input() companyId: any;
  @Input() locaiotn: any;
  @Input() isEdit: any;
  form: FormGroup;
  file: FileParameter = {
    data: "",
    fileName: ""
  };
  isLoading: boolean = false;
  previousLogoUrl: string = "";
  locationModelDto: LocationCreateModel = new LocationCreateModel();
  isCurrenciesLoading: boolean = false;
  isCountriesLoading: boolean = false;
  isDateFormatsLoading: boolean = false;
  currencies: CurrencyModel[] = [];
  countries: CountryModel[] = [];
  dateFormats: DateFormatModel[] = [];
  teethChartingSystems = [];
  teethShadeSystems = [];

  constructor(
    private formBuilder: FormBuilder,
    private _locationService: LocationServiceProxy,
    protected dialogRef: NbDialogRef<CreateLocationModalComponent>,
  ) { }

  ngOnInit(): void {

    if (this.isEdit) {
      this.locationModelDto = this.locaiotn;  
      this.selectedTimeZone = this.locationModelDto.timezoneNumber?  this.locationModelDto.timezoneNumber : 0;
      // this.changePhoneNumCountry();
      this.phoneNubmer = this.locaiotn.phone;
    }
    this.teethChartingSystems = getTeethChartingSystems();
    this.teethShadeSystems = getTeethShadeSystems();
    this.loadCurrencies();
    this.loadCountries();
    this.loadDateFormats();
    this.locationModelDto.companyId = this.companyId;
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      country: [null, [Validators.required, ]],
      address: [null, [Validators.required, ]],
      phone: [null, [Validators.required, ]],
      scanHubEmail: [null],
      taxNumber: [null, [Validators.required, ]],
      logoUrl: [],
      billingEmail: [],
      address2: [],
      town: [],
      city: [],
      postCode: [],
      currency: [null, [Validators.required, ]],
      dateFormat: [null, [Validators.required, ]],
      teethType: [null, [Validators.required, ]],
      shadeSystem: [null, [Validators.required, ]],
      idhLaboratoryAccountNumber: [null],
      timezome: [null]
    });
  }
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  close() {
    this.dialogRef.close(false);
  }
  addCompany() {
    this.dialogRef.close(true);

  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  onUpdate() {
    if (this.form.valid) {
      let editLocationDto: LocationModel = new LocationModel()
        editLocationDto.id = this.locaiotn.id;
        editLocationDto.companyId = this.locaiotn.companyId;
        editLocationDto.name = this.locationModelDto.name;
        editLocationDto.idhLaboratoryAccountNumber = this.locationModelDto.idhLaboratoryAccountNumber;
        editLocationDto.email = this.locationModelDto.email;
        editLocationDto.country = this.locationModelDto.country;
        editLocationDto.address =  this.locationModelDto.address;
        editLocationDto.address2 =  this.locationModelDto.address2;
        editLocationDto.town =  this.locationModelDto.town;
        editLocationDto.city =  this.locationModelDto.city;
        editLocationDto.postCode =  this.locationModelDto.postCode;
        editLocationDto.billingEmail = this.locationModelDto.billingEmail;
        // editLocationDto.phone =  this.locationModelDto.phone;
        editLocationDto.phone = this.phoneNubmer.e164Number;
        editLocationDto.scanHubEmail =  this.locationModelDto.scanHubEmail;
        editLocationDto.logoUrl =  this.locationModelDto.logoUrl;
        editLocationDto.taxNumber =  this.locationModelDto.taxNumber;
        editLocationDto.currency =  this.form.get('currency').value;
        editLocationDto.dateFormat = this.locationModelDto.dateFormat;
        editLocationDto.teethType = this.locationModelDto.teethType;
        editLocationDto.shadeSystem = this.locationModelDto.shadeSystem;
      
        editLocationDto.timezone = this.timezone.find(x => x.timezonecode == this.selectedTimeZone).name;
        editLocationDto.timezoneNumber = this.selectedTimeZone


      this._locationService.update(editLocationDto).subscribe(
        (resp) => {
          this.dialogRef.close(true);
        },
        (error: any) => {

        }
      )
    } else {
      this.validateAllFormFields(this.form);
    }
  }


  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      this.locationModelDto.phone = this.phoneNubmer.e164Number;
      this.locationModelDto.currency = this.form.get('currency').value;
      this.locationModelDto.timezone = this.timezone.find(x => x.timezonecode == this.selectedTimeZone).name;
      this.locationModelDto.timezoneNumber = this.selectedTimeZone
      this._locationService.create(this.locationModelDto).pipe(
        finalize(() => {
          this.isLoading = false;
        }))
       .subscribe(
        (resp) => {
          this.dialogRef.close(true);
        },
        (error: any) => {

        }
      )
    } else {
      this.validateAllFormFields(this.form);
    }
  }



  getNameWithUrl(url: string) {
    if (url) {
      return url.replace(/^.*[\\\/]/, '');
    } else {
      return ' ';
    }
  }

  upload(files: FileList) {
    this.previousLogoUrl =  this.locationModelDto.logoUrl?  this.locationModelDto.logoUrl : '';
    this.isLoading = true;
    this.file.data = files.item(0);
    this.file.fileName = files.item(0).name ?? "";
    this._locationService.uploadLogo(this.companyId,this.file,this.previousLogoUrl).pipe(
      finalize(() => {
        this.isLoading = false;
      }))
      .subscribe(
        (resp) => {
          if (resp.result) {
            this.locationModelDto.logoUrl = resp.message;
          }
        },
        (error: any) => { }
      )
  }

  loadCurrencies() {
    this.isCurrenciesLoading = true;
    this._locationService.getAllCurrencies().pipe(
      finalize(() => {
        this.isCurrenciesLoading = false;
      }))
      .subscribe(
        (result: CurrencyModel[]) => {
          this.currencies = result;
        },
        (error: any) => {
        }
      )
  }

loadCountries() {
    this.isCountriesLoading = true;
    this._locationService.getAllCountries().pipe(
      finalize(() => {
        this.isCountriesLoading = false;
      }))
      .subscribe(
        (result: CountryModel[]) => {
          this.countries = result;
          this.changePhoneNumCountry();
        },
        (error: any) => {
        }
      )
  }

  loadDateFormats() {
    this.isDateFormatsLoading = true;
    this._locationService.getAllDateFormats().pipe(
      finalize(() => {
        this.isDateFormatsLoading = false;
      }))
      .subscribe(
        (result: DateFormatModel[]) => {
          this.dateFormats = result;
        },
        (error: any) => {
        }
      )
  }

  // onCountryChange(countryName: string) {
  //   let country = this.countries.find(c => c.countryName === countryName);
  //   if (!country) {
  //     this.locationModelDto.dateFormat = null; 
  //     return;
  //   }
  //   let countryCode = country.countryCode;
    
  //   let dateFormat = this.dateFormats.find(df => df.countryCode === countryCode);
  //   if (!dateFormat) {
  //     // DD/MM/YYYY
  //     const defaultFormatCountries = [
  //       'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 
  //       'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB'
  //     ];
  //     if (defaultFormatCountries.includes(countryCode)) {
  //       this.locationModelDto.dateFormat = 'DD/MM/YYYY';
  //     } else {
  //       this.locationModelDto.dateFormat = null; 
  //     }
  //   } else {
  //     this.locationModelDto.dateFormat = dateFormat.formatPattern;
  //   }
  // }

  updateDateFormat(countryCode: string) {
    // DD/MM/YYYY
    const defaultFormatCountries = [
      'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 
      'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'UK'
    ];
    if (defaultFormatCountries.includes(countryCode)) {
     this.locationModelDto.dateFormat = 'dd/MM/yyyy';
    } else {
      this.locationModelDto.dateFormat = null; 
    }
  }
  


  updateTeethChartingSystem(countryCode: string) {
    const TeethChartingSystemCountries = {
        [TeethChartingSystem.FDI]: ["UK", "IE", "FR", "DE", "ES", "IT", "RU", "CN", "IN", "PL", "NL", "BE", "SE", "NO", "DK", "FI", "PT", "GR", "AU", "NZ", "ZA", "BR", "AR", "JP", "KR", "CH", "AT", "HU", "CZ", "SK", "SI", "HR", "BA", "ME", "MK", "AL", "RO", "BG", "LT", "LV", "EE", "MD", "UA", "BY", "RS", "TR"],
        [TeethChartingSystem.Universal]: ["US", "CA"],
    };

    let systemFound = Object.entries(TeethChartingSystemCountries).find(([system, countries]) => countries.includes(countryCode));
    if (systemFound) {
        this.locationModelDto.teethType = Number(systemFound[0]);
    } else {
        this.locationModelDto.teethType = null; 
    }
}
  
  onCountryChange(countryName: string) {
    let country = this.countries.find(c => c.countryName === countryName);
    if (!country) {
      this.locationModelDto.dateFormat = null;
      this.locationModelDto.teethType = null;
      return;
    }
    let countryCode = country.countryCode;
    
    this.updateDateFormat(countryCode);
    this.updateTeethChartingSystem(countryCode);
    this.changePhoneNumCountry();
  }
 

  changePhoneNumCountry() {
    let code = this.countries.find(x => x.countryName == this.locationModelDto.country)?.countryCode;
    this.selectedCountryName = code.toLowerCase();
  }


  phoneNubmer: any;
  selectedCountryName: any;
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});

	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}


   getCountryName(countryCode: string): string {
    for (const countryName in CountryISO) {
        if (CountryISO[countryName] === countryCode) {
            return countryName;
        }
    }
    return "Unknown"; // Return "Unknown" if the country code is not found
}


  selectedTimeZone: any = 0;
  timezone: any[] = [
    { timezonecode: -12, name: '(GMT -12:00) Eniwetok, Kwajalein' },
    { timezonecode: -11, name: '(GMT -11:00) Midway Island, Samoa' },
    { timezonecode: -10, name: '(GMT -10:00) Hawaii' },
    { timezonecode: -9, name: '(GMT -9:00) Alaska' },
    { timezonecode: -8, name: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
    { timezonecode: -7, name: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
    { timezonecode: -6, name: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
    { timezonecode: -5, name: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
    { timezonecode: -4, name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
    { timezonecode: -3, name: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
    { timezonecode: -2, name: '(GMT -2:00) Mid-Atlantic' },
    { timezonecode: -1, name: '(GMT -1:00) Azores, Cape Verde Islands' },
    { timezonecode: 0, name: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
    { timezonecode: 1, name: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
    { timezonecode: 2, name: '(GMT +2:00) Kaliningrad, South Africa' },
    { timezonecode: 3, name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
    { timezonecode: 4, name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
    { timezonecode: 5, name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
    { timezonecode: 6, name: '(GMT +6:00) Almaty, Dhaka, Colombo' },
    { timezonecode: 7, name: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
    { timezonecode: 8, name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
    { timezonecode: 9, name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
    { timezonecode: 10, name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
    { timezonecode: 11, name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
    { timezonecode: 12, name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
    { timezonecode: 13, name: '(GMT +13:00) Apia, Nukualofa' },
    { timezonecode: 14, name: '(GMT +14:00) Line Islands, Tokelau' },
  ]

}







