import { Pipe, PipeTransform } from '@angular/core';
import { OrderProductModel } from '../../../shared/service-proxies/event-service-proxy';
@Pipe({
  name: 'automationAddedLast'
})
export class AutomationAddedLast implements PipeTransform {

  transform(value: any[]): any[] {
    return value.sort((n1: OrderProductModel, n2: OrderProductModel) => 
    {
      if (n1.fromBasketAutomationProductId && n2.fromBasketAutomationProductId) {
        return n1.createdAt > n2.createdAt ? 1 : -1;
      }
      return n1.fromBasketAutomationProductId ? 1 : -1; 
    });
  }
}