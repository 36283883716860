export const environment = {
  /**
 * @param urlAddress URL of the API
 * @param frontEndIpAddress IP Address of the Frontend AWS EC2 Instance. 
 * Also, can be domain of the EC2. In this case custom domain will work using CNAME record instead of A.
 * If the ip address is 0.0.0.0 - it means that the application doesn't support custom domains (User will not see custom domain option in the settings)
 */
  production: false,

  // DEV
// urlAddress: 'https://localhost:7238',
 // frontEndIpAddress: '127.0.0.1', // ONLY for development. Use windows/macos hosts file to map this IP to the domain name

  // DEVs
 // urlAddress: 'http://localhost:5080',
  //frontEndIpAddress: '127.0.0.1', // ONLY for development. Use windows/macos hosts file to map this IP to the domain name

  // KLINE
  // urlAddress: "https://kline-great-api.greatlab.io",
  // frontEndIpAddress: '0.0.0.0',

  // DEMO
   urlAddress: 'https://api-demo.greatlab.io',
   frontEndIpAddress: '18.198.129.198',

  // US
  // urlAddress: 'https://api-us.greatlab.io',
  // frontEndIpAddress: '18.118.233.134',

  // AUS
  // urlAddress: 'https://api-aus.greatlab.io',
  // frontEndIpAddress: '13.236.157.214',

  // UK
  // urlAddress: 'https://api-uk.greatlab.io',
  // frontEndIpAddress: '18.171.199.48',

  // STAGING
  // urlAddress: 'https://api-staging.greatlab.io',
  // frontEndIpAddress: 'https://staging-v2.greatlab.io.s3-website.eu-west-2.amazonaws.com', 

  // PORTAL
  // urlAddress: 'https://portal-api.greatlab.io',
  // frontEndIpAddress: '13.43.184.110',

  // ALS
  // urlAddress: 'https://als-api.greatlab.io',
  // frontEndIpAddress: '18.168.172.128',
};
