<div class="locaction-select-section" id="selected_location">
    <div class="change-location-select" (click)="onShowDropdown()" #locationClick>
        <nb-icon icon="arrow-down-black" pack="great-pack"></nb-icon>
        <ng-select [items]="locationItems | searchFilter: searchLocation"
        (mouseleave)="onSelectClick($event)" 
        class="location-select-dropdown" 
        (mouseenter)="onShowDropdown()"
        (close)="onSelectClick($event)" 
        [loading]="isReceivedLoading" 
        (change)="selectLocation()"
        [(ngModel)]="locationId"
        #selectDropdownLocation 
        [closeOnSelect]="false" 
        [searchable]="false" 
        [appendTo]="'body'"
        [clearable]="false"
        [markFirst]="true" 
        [loading]="false"  
        bindLabel="name" 
        bindValue="id" >
            <ng-template ng-header-tmp>
              <div class="input-locations-search-group">
                <img class="search-icon" src="../../../../assets/images/icons/search-input-black.svg" alt="">
                <input (keyup)="getLocations()" [(ngModel)]="searchLocation" placeholder="Search" type="text" nbInput fullWidth>
              </div>
              <div class="company-label">
                {{userData.company}}
              </div>
            </ng-template>
            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                <div class="ng-option disabled p-0">
                    
                </div>
            </ng-template>
            <ng-template ng-loadingtext-tmp>
                <div class="ng-option disabled p-0">
                </div>
            </ng-template>
            <!-- nbPopoverTrigger="hint" [nbPopover]="item.name"
            | ngxCutText: 18 -->
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="location-name">
                    {{item.name}}
                </div>
            </ng-template>
        </ng-select>
    </div>
</div>
<!-- <div *ngIf="!selectedLocation" class="overlay-background"></div> -->

