


<div class="w-100" [nbSpinner]="isLoading" nbSpinnerSize="large" nbSpinnerStatus="basic" [ngClass]="{'small-cart' : type == 2}">
    <label fileDragDrop (filesChangeEmiter)="dragUpload($event,fields)"
      class="w-100" [for]="'uploadFile' + fields">
<div class="upload-section">
    <div class="upload-icon">
        <img src="../../../../assets/images/icons/upload-files-cart.svg" alt="">
    </div>
    <div class="upload-label">
        Add or Drop Files Here
        <!-- <ngx-file-uploader> </ngx-file-uploader> -->
    </div>
    

</div>
</label>
<input [id]="'uploadFile' + fields" 
(change)="orderFileUpload($event.target.files,fields)" hidden multiple type="file" name="image" />
</div>
<div class="upload-file-info mb-3" >
    <div *ngIf="uploadedFiles.length > 0" class="upload-file-info-title">Uploaded files</div>   
    <div *ngFor="let file of order.orderFiles let i = index" class="upload-file-info-filename">
        <ng-container *ngIf="file.typeId == fields">
            <nb-icon icon="file-text-outline"></nb-icon>
            <a class="non-href" target="_blank" [href]="file.url">{{getNameFile(file.url)}}</a>
            <nb-icon (click)="downloadFile(file.url)" class="action-file-icon ml-2" icon="download-outline"></nb-icon>
            <nb-icon class="action-file-icon ml-2" *ngIf="file.url | stlFile" pack="great-pack" icon="stl-icon"
                (click)="openView(file)"></nb-icon>
            <nb-icon class="action-file-icon ml-2" icon="trash-2-outline" (click)="deleteFile(file.id)"></nb-icon>
        </ng-container>
    </div>
  </div>
  