export const orderProductBasedRuleFields: RuleField[] = [
    {
        id: 'productName',
        fieldName: "Product Name",
        fieldPath: "ProductName"
    },
    {
        id: 'sku',
        fieldName: "Product SKU",
        fieldPath: "SKU"
    },
    {
        id: 'productCategory',
        fieldName: "Product Category",
        fieldPath: "Product.Category.Name"
    },
    {
        id: 'productSubcategory',
        fieldName: "Product Subcategory",
        fieldPath: "Product.Subcategory.Name"
    },
    {
        id: 'productMaterial',
        fieldName: "Product Material",
        fieldPath: "Product.Material.Name"
    },
    {
        id: 'productPrice',
        fieldName: "Product Price",
        fieldPath: "Product.Price"
    },
    {
        id: 'productStandard',
        fieldName: "Product Standard",
        fieldPath: "ProductStandardTierName"
    },
    {
        id: 'receivingCategory',
        fieldName: "Receiving Category",
        fieldPath: "Order.ReceivingType"
    },
    {
        id: 'receivingSource',
        fieldName: "Receiving Source",
        fieldPath: "Order.ReceivingOption"
    },
    {
        id: 'dentistName',
        fieldName: "Doctor Name",
        fieldPath: "Order.Dentist.FullName"
    },
    {
        id: 'practiceName',
        fieldName: "Practice Name",
        fieldPath: "Order.Practice.Name"
    },
    {
        id: 'dentistUserName',
        fieldName: "Doctor Username",
        fieldPath: "Order.Dentist.UserName"
    },
    {
        id: 'dentistEmail',
        fieldName: "Doctor Email",
        fieldPath: "Order.Dentist.Email"
    },
    {
        id: 'dentistAccountCode',
        fieldName: "Doctor Account Code",
        fieldPath: "Order.Dentist.AccountCode"
    },
    {
        id: 'productTeeth',
        fieldName: "Product Teeth",
        fieldPath: "Teeth"
    },
    {
        id: 'productArch',
        fieldName: "Product Arch",
        fieldPath: "Arch"
    },
    {
        id: 'practiceRoute',
        fieldName: "Practice - Route",
        fieldPath: "Order.Practice.LocationRoute.Name"
    },
];

export const orderBasedRuleFields: RuleField[] = [
    {
        id: 'productName',
        fieldName: "Product Name",
        fieldPath: "OrderProductsForRules.Any().ProductName"
    },
    {
        id: 'sku',
        fieldName: "Product SKU",
        fieldPath: "OrderProductsForRules.Any().SKU"
    },
    {
        id: 'productCategory',
        fieldName: "Product Category",
        fieldPath: "OrderProductsForRules.Any().Product.Category.Name"
    },
    {
        id: 'productSubcategory',
        fieldName: "Product Subcategory",
        fieldPath: "OrderProductsForRules.Any().Product.Subcategory.Name"
    },
    {
        id: 'productMaterial',
        fieldName: "Product Material",
        fieldPath: "OrderProductsForRules.Any().Product.Material.Name"
    },
    {
        id: 'productPrice',
        fieldName: "Product Price",
        fieldPath: "OrderProductsForRules.Any().Product.Price"
    },
    {
        id: 'productStandard',
        fieldName: "Product Standard",
        fieldPath: "OrderProductsForRules.Any().ProductStandardTierName"
    },
    {
        id: 'receivingCategory',
        fieldName: "Receiving Category",
        fieldPath: "ReceivingType"
    },
    {
        id: 'receivingSource',
        fieldName: "Receiving Source",
        fieldPath: "ReceivingOption"
    },
    {
        id: 'dentistName',
        fieldName: "Doctor Name",
        fieldPath: "Dentist.FullName"
    },
    {
        id: 'practiceName',
        fieldName: "Practice Name",
        fieldPath: "Practice.Name"
    },
    {
        id: 'dentistUserName',
        fieldName: "Doctor Username",
        fieldPath: "Dentist.UserName"
    },
    {
        id: 'dentistEmail',
        fieldName: "Doctor Email",
        fieldPath: "Dentist.Email"
    },
    {
        id: 'dentistAccountCode',
        fieldName: "Doctor Account Code",
        fieldPath: "Dentist.AccountCode"
    },
    {
        id: 'productTeeth',
        fieldName: "Product Teeth",
        fieldPath: "OrderProductsForRules.Any().Teeth"
    },
    {
        id: 'productArch',
        fieldName: "Product Arch",
        fieldPath: "OrderProductsForRules.Any().Arch"
    },
    {
        id: 'practiceRoute',
        fieldName: "Practice - Route",
        fieldPath: "Practice.LocationRoute.Name"
    },
];

export enum RuleFieldsBase {
    OrderProduct = 1,
    Order,
}

export class RuleField {
    id: string;
    fieldName: string;
    fieldPath: string;
}