<section class="upload-photo-section">



    <div class="manual-upload" [nbSpinner]="isLoading" nbSpinnerStatus="info">









        <div fileDragDrop (filesChangeEmiter)="dragUpload($event,type.typeId)"
         class="upload-item" *ngFor="let type of updatedFileTypesListDto, let i = index">
            <input [id]="'photos' + type.typeId"
            (change)="orderFileUpload($event.target.files,type.typeId)" hidden type="file"
            name="image" />
            <label  class="file-box" *ngIf="!type.url" [for]="'photos' + type.typeId">
                <div class="emtpy-upload-icon">
                    <img src="../../../../assets/images/icons/empty-upload.svg" alt="">
                </div>
                <div class="file-type-name">
                   {{type.name}}
                </div>
            </label>
            <div class="file-box" *ngIf="type.url">
                <a class="done-upload" data-fancybox="gallery" [href]="type.url">
                    <img class="uploaded-file" [src]="type.url" alt="">
                </a>
                <label class="finish-upload-icon" [for]="'photos' + type.typeId">
                    <nb-icon class="restore-photos" icon="flip-2-outline"></nb-icon>
                    <!-- <img class="success-file" src="../../../../assets/images/icons/success-uploading.png" alt=""> -->
                  </label>
          
            </div>
         
        </div>
      
    </div>
</section>