<div class="wrapper">
    <lr-config
      ctx-name="my-uploader"
      pubkey="66f9b2fe2948d39a10aa"
      multiple="true"
      multipleMax="10"
      confirmUpload="true"
      sourceList="local, url, camera"
    ></lr-config>
    <lr-file-uploader-regular
      ctx-name="my-uploader"
      [attr.css-src]="cssSrc"
    >
    </lr-file-uploader-regular>
    <lr-data-output
      ctx-name="my-uploader"
      (lr-data-output)="handleUploaderEvent($event)"
      use-event
      hidden
    ></lr-data-output>
  
    <div class="output">
      <img
        *ngFor="let file of files"
        [src]="'https://ucarecdn.com/' + file.uuid + '/' + (file.cdnUrlModifiers || '') + '-/preview/-/scale_crop/400x400/'"
        width="200"
        alt="Uploaded file preview"
      />
    </div>
  </div>
  