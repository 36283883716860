import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomPredicate, FieldComparisonOperator, LogicalOperator, PredicateGroup } from '../../../../shared/service-proxies/event-service-proxy';
import { orderBasedRuleFields, orderProductBasedRuleFields, RuleField, RuleFieldsBase } from '../../../@core/models/ruleFields';
import { fieldComparisonOperators, noValueOperator, operatorRequiresNumber } from '../../../@core/models/fieldComparisonOperators';

@Component({
  selector: 'ngx-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {
  //never assign rules to another object link directly, call "assignNewRulesObject" method instead
  @Input() rules: PredicateGroup;
  @Input() validate: boolean = false;
  ruleFields: RuleField[];
  fieldComparisonOperators = fieldComparisonOperators;
  @Output() rulesChange = new EventEmitter<PredicateGroup>();
  @Input() required: boolean = true;
  @Input() ruleFieldsBase: RuleFieldsBase;

  constructor() {
  }

  ngOnInit(): void {
    switch(this.ruleFieldsBase) {
      case RuleFieldsBase.OrderProduct:
        this.ruleFields = orderProductBasedRuleFields;
        break;
      case RuleFieldsBase.Order:
        this.ruleFields = orderBasedRuleFields;
        break;
      default:
        throw "Invalid rule fields base";
    }
  }

  checkOperatorDoesNotNeedValue(operator: FieldComparisonOperator | null): boolean {
    if (!operator) {
      return false;
    }
    return noValueOperator(operator);
  }

  checkOpratorRequiresNumber(operator: FieldComparisonOperator | null): boolean {
    if (!operator) {
      return false;
    }
    return operatorRequiresNumber(operator);
  }

  lastCondition = lastCondition;

  removeCondition(predicate: CustomPredicate, group: PredicateGroup): void {
    if (lastCondition(this.rules)) {
      this.assignNewRulesObject(getDefaultRules());
    }
    else {
      let index = group.predicates.indexOf(predicate);
      if (index >= 0) {
        group.predicates.splice(index, 1);
        if (group.predicates.length == 0) {
          this.clearEmptyGroups(this.rules);
        }
      }
    }
  }

  assignNewRulesObject(newRules: PredicateGroup) {
    this.rules = newRules;
    this.rulesChange.emit(this.rules);
  }

  clearEmptyGroups(group: PredicateGroup) {
    group.predicateGroups = group.predicateGroups.filter(x => !this.groupIsEmpty(x));

    group.predicateGroups.forEach(x => this.clearEmptyGroups(x));
  }

  groupIsEmpty(group: PredicateGroup): boolean {
    return group.predicates.length == 0 && group.predicateGroups.every(x => this.groupIsEmpty(x));
  }

  addCondition(group: PredicateGroup): void {
    if (group.logicalOperator == LogicalOperator.Or) {
      let newGroup = new PredicateGroup();
      newGroup.negative = false;
      newGroup.logicalOperator = LogicalOperator.And;
      newGroup.predicateGroups = [];

      let newPredicate = new CustomPredicate();
      newPredicate.negative = false;

      newGroup.predicates = [newPredicate];

      group.predicateGroups.push(newGroup);
    }
    else if (group.logicalOperator == LogicalOperator.And) {
      let newPredicate = new CustomPredicate();
      newPredicate.negative = false;

      group.predicates.push(newPredicate);
    }
  }

  isDefaultRuleset = isDefaultRuleset;

  allowRuleDelete() : boolean {
    return !(this.required && lastCondition(this.rules) || isDefaultRuleset(this.rules));
  }
}

//use just before submit to clean unneeded data
export const cleanUpGroup = (group: PredicateGroup): void => {
  group.predicates.forEach(predicate => {
    if (!predicate.fieldComparisonOperator || noValueOperator(predicate.fieldComparisonOperator)) {
      predicate.value = null;
    }
  });

  group.predicateGroups.forEach(x => cleanUpGroup(x));
}

//determines if group is just default seeded
export const isDefaultRuleset = (group: PredicateGroup): boolean => {
  return group.predicates.length === 0 &&
    group.predicateGroups.length === 1 &&
    group.predicateGroups[0].predicateGroups.length === 0 &&
    group.predicateGroups[0].predicates.length === 1 &&
    !group.predicateGroups[0].predicates[0].field &&
    !group.predicateGroups[0].predicates[0].fieldComparisonOperator &&
    !group.predicateGroups[0].predicates[0].value;
}

//use to seed default rules from outside (REQUIRED TO SET RULES ON CREATION BY THIS FUNCTION)
export const getDefaultRules = (): PredicateGroup => {
  let topLevelGroup = new PredicateGroup();
  topLevelGroup.logicalOperator = LogicalOperator.Or;
  topLevelGroup.negative = false;
  topLevelGroup.predicates = [];

  let defaultGroup = new PredicateGroup();
  defaultGroup.logicalOperator = LogicalOperator.And;
  defaultGroup.negative = false;
  defaultGroup.predicateGroups = [];

  let defaultPredicate = new CustomPredicate();
  defaultPredicate.negative = false;

  defaultGroup.predicates = [defaultPredicate];

  topLevelGroup.predicateGroups = [defaultGroup];

  return topLevelGroup;
}

//use to make sure rules are not default preseeded and all fields are filled
export const rulesValid = (rules: PredicateGroup, required: boolean = true) : boolean => {
  if (required && (isDefaultRuleset(rules) || getConditions(rules).length === 0)) {
    return false;
  }

  if (!required && isDefaultRuleset(rules)) {
    return true;
  }

  return rules.predicates.every(x => 
    x.field &&
    x.fieldComparisonOperator &&
    (x.value || noValueOperator(x.fieldComparisonOperator))
  ) && 
  rules.predicateGroups.every(x => rulesValid(x, false));
}

//use to determine for example if delete rule icon should be hidden when at least 1 rule is required
export const lastCondition = (rules: PredicateGroup): boolean => {
  return getConditions(rules).length == 1;
}

//returns flat array of rules
export const getConditions = (group: PredicateGroup): CustomPredicate[] => {
  return group.predicateGroups.reduce((prev, cur) => {
    return prev.concat(getConditions(cur));
  }, group.predicates);
}