import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { finalize } from 'rxjs/operators';
import { OrderServiceProxy, ReceivingSettingServiceProxy, ReceivingSettingsModel } from '../../../../shared/service-proxies/event-service-proxy';

@Component({
  selector: 'ngx-impressions-received-select',
  templateUrl: './impressions-received-select.component.html',
  styleUrls: ['./impressions-received-select.component.scss']
})
export class ImpressionsReceivedSelectComponent implements OnInit, AfterViewInit {
  @ViewChild('selectDropdownReceiving') selectDropdown: NgSelectComponent;
  @ViewChild('locationClick', { static: true }) locationClick: ElementRef;
  @Output() outPutEventVal = new EventEmitter<any>();
  @Input() order: any;
  @Input() triggerAutomations: boolean = false;
  @Input() receivingType: string|null = null;
  @Input() receivingOption: string|null = null;
  isReceivedLoading: boolean = false;
  receivingOptionSelect: any;
  receivingSettingsModelDto: ReceivingSettingsModel = new ReceivingSettingsModel();
  countOfAnalog: any[] = [];
  constructor(
    private _cd: ChangeDetectorRef,
    private _receivingService: ReceivingSettingServiceProxy,
    private _orderService: OrderServiceProxy
  ) { }
  tmpArrayOption: any[] = [];
  changeReceiving() {
    if (this.order.receivingOption) {
      const myCustomClass: string = "overlay-opened-ng-panel";
      const panel = document.querySelector('.ng-dropdown-panel');

      if (panel && panel.classList.contains(myCustomClass)) {
        panel.classList.remove(myCustomClass);
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.order.receivingOption) {
      this.selectDropdown.open();
      if (this.locationClick) {
        this.locationClick.nativeElement.click();
        setTimeout(() => {
          const myCustomClass: string = "overlay-opened-ng-panel"
          const panel = document.querySelector('.ng-dropdown-panel');
          panel.classList.add(myCustomClass);
        }, 100);

      }
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.receivingType) this.order.receivingType = this.receivingType;
    if (this.receivingOption) this.order.receivingOption = this.receivingOption;
    if (this.order.receivingType && this.order.receivingOption) {
      if (this.order.receivingType == 'Analogue') {
        this.tmpArrayOption = this.convertStringToArray(this.order.receivingOption);
        this.receivingOptionSelect = this.order.receivingOption;
      } else {
        this.receivingOptionSelect = this.order.receivingOption;
      }
    } else {
      this.receivingOptionSelect = null;
    }
  
    try {
      await this.getReceivingListOption();
    } catch (error) {
      console.error('Error fetching receiving list options', error);
    }
  }
  
  getReceivingListOption(): Promise<void> {
    this.isReceivedLoading = true;
  
    return new Promise<void>((resolve, reject) => {
      this._receivingService.getSettingsByLocationId(this.order.locationId).pipe(
        finalize(() => {
          this.isReceivedLoading = false;
        })
      ).subscribe(
        (result) => {
          this.receivingSettingsModelDto = result;
          this.receivingSettingsModelDto.receivingOptions.map((x: any) => {
            if (this.tmpArrayOption.find(a => a.name == x.name)) {
              x.count = this.tmpArrayOption.find(a => a.name == x.name).count;
            } else {
              x.count = 0;
            }
          });
  
          this.countOfAnalog = Array.from(
            { length: this.receivingSettingsModelDto.analogueUnitsLimit + 1 },
            (v, k) => ({ id: k, name: (k).toString() })
          );
  
          resolve();
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }
  
  convertStringToArray(str: any) {
    let arrDto: any[] = [];
    arrDto = str.split(',').map(item => {
      let [count, name] = item.split('_');
      return { name, count: parseInt(count) };
    });
    return arrDto;
  }

 
  isSelectOpened: boolean = false;
  onDropdownOpened(event: any): void {
    this.isSelectOpened = true;
  }

  onDropdownClosed(event: any): void {
    this.isSelectOpened = false;
  }
  onCloseDropdown() {
    if (this.order.receivingType == 'Analogue') {
      let items = this.receivingSettingsModelDto.receivingOptions.filter((x: any) => x.count !== 0);
      if (items.length == 0) {
        this.receivingOptionSelect = null;
        this.order.receivingOption = null;
        this.order.receivingType = null;
        return false
      }
      let result = items.map((a: any) => {
        let item = `${a.count}_${a.name}`;
        return item;
      }).join(',');
      this.order.receivingOption = result;
    } else {

    }
    this.updateOrder();
    this.selectDropdown.close();
  }
  onShowDropdown() {
    this.selectDropdown.open();
  }

  mouseSetterType(type: any) {
    if (type == 'notApplicable') {
      return false;
    }
    if (this.order.receivingType == null) {
      this.order.receivingType = type
    }
  }

  selectReceiving(type: any) {
    if (type == 'notApplicable') {
      this.order.receivingOption = 'notApplicable';
      this.receivingOptionSelect = 'notApplicable';
      this.changeReceiving();
    }
    if (type !== this.order.receivingType) {
      this.order.receivingType = type;
    } else {
      this.order.receivingType = null;
    }

  }

  updateOrder() {
    this._orderService.simpleFlowUpdate(this.triggerAutomations, this.order).pipe(
      finalize(() => {
      }))
      .subscribe(
        (result) => {
          if (this.triggerAutomations && this.order.orderProducts.length < result.orderProducts.length)
            this.outPutEventVal.emit();
        },
        (error: any) => { }
      )
  }


  onSelectClick(event: any) {
    if (this.order.receivingOption) {
      this.onCloseDropdown();
    }
  }


}
