import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasketAutomationProductModel } from '../../../../shared/service-proxies/event-service-proxy';

@Component({
  selector: 'ngx-basket-automation-products-list',
  templateUrl: './basket-automation-products-list.component.html',
  styleUrls: ['./basket-automation-products-list.component.scss']
})
export class BasketAutomationProductsListComponent implements OnInit {
  @Input() basketAutomationProducts: BasketAutomationProductModel[] = [];
  @Output() basketAutomationProductsChange: EventEmitter<BasketAutomationProductModel[]> = new EventEmitter<BasketAutomationProductModel[]>();
  @Input() required: boolean = false;
  @Input() validate: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  deleteProduct(basketAutomationProduct: BasketAutomationProductModel) {
    const index = this.basketAutomationProducts.indexOf(basketAutomationProduct);

    this.basketAutomationProducts.splice(index, 1);
    this.basketAutomationProductsChange.emit(this.basketAutomationProducts);
  }
}
