import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
@Injectable({
  providedIn: 'root'
})
/**
 * Service for working with posthog
 */
export class PosthogService {
  constructor() {
    posthog.init("phc_80xANptYaenHr13vjWWP3m2vgQcghVbd66Jo1xqbVVu", { api_host: "https://us.i.posthog.com", person_profiles: 'identified_only' });
    this.updateProperties();
  }
  /**
   * Обновляет зависимые параметры пользователя
   */
  private updateProperties() {
    this.updateServerLocation();
  }
  /**
   * Обновляет параметры пользователя в posthog
   * @param key Ключ параметра
   * @param value Значение параметра
   */
  updateUserProperty(key: string, value: any) {
    posthog.people.set({ [key]: value });
  }
  /**
   * Обновляет сервер пользователя через суб-домен
   */
  private updateServerLocation() {
    const hostname = window.location.hostname;
    const subdomainMatch = hostname.match(/^([^.]+)\./);
    const locationSubdomain = subdomainMatch ? subdomainMatch[1] : '';
    this.updateUserProperty('server_location', locationSubdomain);
    this.group("domain", hostname, {
      "name": hostname
    });
  }
  /**
   * Устанавливает новые параметры posthog
   * @param properties Параметры в формате ключ-значение
   */
  updateUserProperties(properties: { [key: string]: any }) {
    posthog.people.set(properties);
  }
  /**
   * Авторизация пользователя
   * @param userId логин пользователя
   * @param userProperties параметры (Отправляются в posthog)
   */
  identifyUser(userId: string, userProperties?: any) {
    posthog.identify(userId, userProperties);
  }
  /**
   * Добавляет пользователя в группу
   */
  group(name: string, id: string, properties?:any) {
    posthog.group(name, id, properties);
  }
  /**
   * Фиксация события в posthog
   * @param eventName Название события
   * @param properties Параметры (Отправляются в posthog)
   */
  captureEvent(eventName: string, properties?: any) {
    posthog.capture(eventName, properties);
  }
  /**
   * Returns true if the feature flag is enabled for the current user.
   * @param flag Feature flag name
   * @returns Is feature enabled
   */
  isFeatureEnabled(flag: string) : boolean {
    const enabled = posthog.isFeatureEnabled(flag);
    return enabled;
  }
}