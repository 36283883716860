import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { FileParameter, OrderFileModel, OrderServiceProxy, UploadOrderFileResponseModel } from '../../../../shared/service-proxies/event-service-proxy';
import { UPLOADFILETPYES } from '../file-types-for-order';

@Component({
  selector: 'ngx-upload-photos-by-order',
  templateUrl: './upload-photos-by-order.component.html',
  styleUrls: ['./upload-photos-by-order.component.scss']
})
export class UploadPhotosByOrderComponent implements OnInit {
  @Input() order: any;
  isLoading: boolean = false;
  fileTypesListDto: any[] = [
    { url: null, typeId: 1, name: 'Webcam' },
    { url: null, typeId: 2, name: 'Upper Teeth' },
    { url: null, typeId: 3, name: 'Lower Teeth' },
    { url: null, typeId: 4, name: 'Left Side Bite' },
    { url: null, typeId: 5, name: 'Right Side Bite' },
    { url: null, typeId: 6, name: 'Side Profile' },
    { url: null, typeId: 7, name: 'Full Face' },
    { url: null, typeId: 8, name: 'XRay' },
]
  orderFile: FileParameter = { data: null, fileName: null };
  orderFileData: File;
  constructor(
    private _orderService: OrderServiceProxy,
    private cdr: ChangeDetectorRef
  ) { }

  get updatedFileTypesListDto() {
    if (this.order.orderFiles.length > 0) {
      this.order.orderFiles.map((item: any) => {
        const fileType = this.fileTypesListDto.find(x => x.typeId == item.typeId);
        if (fileType) {
          fileType.url = item.url;
        }
        return item;
      });
    }
    return this.fileTypesListDto;
  }

  ngOnInit(): void {
    if (this.order.orderFiles.length > 0) {
      this.order.orderFiles.map((item: any) => {
        if (this.fileTypesListDto.find(x => x.typeId == item.typeId))
        this.fileTypesListDto.find(x => x.typeId == item.typeId).url = item.url;
        return item;
      })
    }
  }

orderUpdate() {

  this._orderService.update(this.order).pipe(
    finalize(() => {
    }))
    .subscribe(
      (result) => {
      },
      (error: any) => { }
    )
}
dragUpload(files: any, type: any) {
  this.orderFileUpload(files, type);
}

  orderFileUpload(files: FileList, type: number) {
    this.isLoading = true;
    this.orderFile.data = files.item(0);
    this.orderFile.fileName = files.item(0).name ?? "";
    this._orderService.uploadOrderFile(this.orderFile, this.order.id).pipe(
      finalize(() => {
        this.isLoading = false;
      }))
      .subscribe(
        (result: UploadOrderFileResponseModel) => {
          let item = this.fileTypesListDto.find(x => x.typeId == type);
          item.url = result.url;
          // if (this.order.orderFiles.length > 0 && this.order.orderFiles.find(x => x.typeId == type)) {
          //   this.order.orderFiles.find(x => x.typeId == type).url = result.url;
          // } else {
          //   let pushFile: OrderFileModel = new OrderFileModel();
          //   pushFile.orderId = this.order.id;
          //   pushFile.typeId = type;
          //   pushFile.url = result.url;
          //   this.order.orderFiles.push(pushFile);
          // }

          this._orderService
          .addUploadedFileToOrder('00000000-0000-0000-0000-000000000000', this.order.id, type, result.url, "").pipe(
            finalize(() => {
              this.isLoading = false;
            }))
          .subscribe(
            (result: OrderFileModel) => {
              this.order.orderFiles?.push(result);
            },
            (error: any) => {
            })
        
        },
        (error: any) => { }
      )
  }

  test() {

        
  }



}
