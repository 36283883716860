<nb-card class="modal-card">
    <div class="title">
        Add Custom Fee
    </div>
    <div class="form-group">
        <label for="input-name">Name</label>
        <div class="input-container">
            <input type="text" [(ngModel)]="data.name" nbInput />
        </div>
    </div>
    <div class="form-group">
        <label for="input-amount">Amount</label>
        <div class="input-container">
            <div class="currency_symbol">
                {{ currencySymbol }}
            </div>
            <input type="text" pattern="^\d+(\.\d{0,2})?$"  inputmode="decimal"  [(ngModel)]="data.amount" placeholder="30.00" nbInput/>
        </div>
    </div>
    <hr class="mt-5" style="width:100%">
    <nb-card-footer>
        <button nbButton class="exit" (click)="close()">Cancel</button>
        <button nbButton status="basic" class="btn-default continue" (click)="submit()">Add</button>
    </nb-card-footer>
</nb-card>